

















import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { mapGetters, mapState, mapActions } from 'vuex'
import { validationMixin } from 'vuelidate'

// import { STORE_KEY, store } from './BrandForm/_store';

@Component({
  mixins: [validationMixin],
  computed: {
    // ...mapState(STORE_KEY, [
    //   'loaded', 'loading', 'fatalError', 'serverErrors',
    //   'form', 'formDirty',
    // ]),
  },
})

export default class BrantDetails extends Vue {

}
